import * as React from "react";
import Navbar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GoBackButton from "../components/goBackButton";

const Product = () => {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    const { innerWidth: width } = window;
    width > 750 ? setMobile(false) : setMobile(true);
  }, []);

  const elementRef = React.useRef();
  const [elHeight, setElHeight] = React.useState(315);
  React.useEffect(() => {
    setElHeight(elementRef.current?.clientHeight);
  }, [elementRef]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Merth.dev House Keeping</title>
      </Helmet>
      <Navbar />
      <main className=" bg-primary">
        <h1 className="text-center">House Keeping</h1>
        <div className="row justify-content-center">
          <div className="row py-4 h-100 ">
            <div
              className={`col-md-6 text-center  h-100 align-self-center ${
                mobile ? "g-0 pb-1" : "px-4"
              }`}
            >
              <iframe
                width="100%"
                height={`${!mobile ? elHeight + "px" : ""}`}
                src="https://www.youtube.com/embed/jfCR-aV96Hs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div ref={elementRef} className="col-md-6 bg-paragraph">
              <p
                className={` ${mobile ? "p-2 fs-6" : "p-4 justify-text fs-5"}`}
              >
                This modernly designed SPA (Single Page Application) and mobile
                app communicate with a C# API (developed by another developer)
                via HTTP requests. They provide access to the host machine's
                folders, allowing users to select a target folder and set
                threshold values. The application then monitors disk space and
                automatically deletes files when the disk-free space reaches the
                assigned threshold. Real-time information on disk space and
                deleted files is displayed on both the web and mobile
                applications.
                <br />
                <i>
                  <small>Used technologies : ReactJs and React Native.</small>
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${mobile ? "" : "px-3"}`}>
          <StaticImage
            src={`./../images/houseKeeping.jpg`}
            alt="service"
            placeholder="blurred"
          />
        </div>
        <GoBackButton />
      </main>
    </>
  );
};

export default Product;
